import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { postcodeRegEx } from '/src/functions/regEx'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CheckBox = loadable(() => import('/src/components/Form/CheckBox'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const FormSection = loadable(() => import('/src/components/Form/FormSection'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    consent: Yup.object({
        post: Yup.boolean(),
        sms: Yup.boolean(),
        phone: Yup.boolean(),
        email: Yup.boolean()
    }).test('consent-ok', function (value, { createError, path }) {
        if (value.post || value.sms || value.phone || value.email) {
            return true // everything is fine
        }
        return createError({
            path,
            message: 'You must select at least one option'
        })
    }),
    address: Yup.string().when('consent.post', {
        is: true,
        then: Yup.string().required('Address is required')
    }),
    postcode: Yup.string()
        .matches(postcodeRegEx, 'Postcode is not valid. Please ensure you have added a space')
        .when('consent.post', {
            is: true,
            then: Yup.string().required('Postcode is required')
        }),
    mobile: Yup.string()
        .max(15, 'Cannot be longer than 15 digits')
        .when('consent.sms', {
            is: true,
            then: Yup.string().required('Mobile phone number is required')
        }),
    phone: Yup.string()
        .max(15, 'Cannot be longer than 15 digits')
        .when('consent.phone', {
            is: true,
            then: Yup.string().required('Phone number is required')
        }),
    email: Yup.string()
        .email('Email address is not valid')
        .when('consent.email', {
            is: true,
            then: Yup.string().required('Email address is required')
        })
})

const MarketingOptInForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    const [isAddressField, setAddressField] = useState(false)
    const [isMobileField, setMobileField] = useState(false)
    const [isPhoneField, setPhoneField] = useState(false)
    const [isEmailField, setEmailField] = useState(false)

    const toggleAddressField = () => {
        setAddressField((prev) => !prev)
    }

    const toggleMobileField = () => {
        setMobileField((prev) => !prev)
    }

    const togglePhoneField = () => {
        setPhoneField((prev) => !prev)
    }

    const toggleEmailField = () => {
        setEmailField((prev) => !prev)
    }

    return (
        <Formik
            initialValues={{
                firstname: '',
                lastname: '',
                consent: {
                    post: false,
                    sms: false,
                    phone: false,
                    email: false
                },
                address: '',
                postcode: '',
                mobile: '',
                phone: '',
                email: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    firstname: values.firstname,
                    lastname: values.lastname,
                    consent: {
                        post: values.consent.post,
                        sms: values.consent.sms,
                        phone: values.consent.phone,
                        email: values.consent.email
                    },
                    address: values.address,
                    postcode: values.postcode,
                    mobile: values.mobile,
                    phone: values.phone,
                    email: values.email
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'opt-in',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_MARKETING_EMAIL_ADDRESS,
                    emailTemplate: 'opt-in-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormRow>
                        <TextField
                            label={'First name'}
                            name={'firstname'}
                            placeholder={'e.g. Emma'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Last name'}
                            name={'lastname'}
                            placeholder={'e.g. Jones'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <FieldSet
                            variant={'column'}
                            legend={'Opt-in to the following communications:'}
                            groupName={'consent'}
                            required
                        >
                            <FieldSpacer>
                                <CheckBox
                                    label={'Yes please, I would like to receive communications by post'}
                                    name={'consent.post'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleAddressField}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={'Yes please, I would like to receive communications by SMS'}
                                    name={'consent.sms'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleMobileField}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={'Yes please, I would like to receive communications by phone'}
                                    name={'consent.phone'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={togglePhoneField}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={'Yes please, I would like to receive communications by email'}
                                    name={'consent.email'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleEmailField}
                                />
                            </FieldSpacer>
                        </FieldSet>
                    </FormRow>

                    {(isAddressField || isMobileField || isPhoneField || isEmailField) && (
                        <FormSection>
                            {isAddressField && (
                                <>
                                    <FormRow>
                                        <TextField
                                            label={'House number/name and road'}
                                            name={'address'}
                                            placeholder={'e.g. 105 Dover Road'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            required
                                        />
                                    </FormRow>

                                    <FormRow>
                                        <TextField
                                            label={'Postcode'}
                                            name={'postcode'}
                                            placeholder={'e.g. ZE2 7UL'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            required
                                        />
                                    </FormRow>
                                </>
                            )}

                            {isMobileField && (
                                <FormRow>
                                    <TextField
                                        label={'Mobile phone number'}
                                        name={'mobile'}
                                        format={'number'}
                                        placeholder={'e.g. 07700900796'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FormRow>
                            )}

                            {isPhoneField && (
                                <FormRow>
                                    <TextField
                                        label={'Phone number'}
                                        name={'phone'}
                                        format={'number'}
                                        placeholder={'e.g. 01202 123456'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FormRow>
                            )}

                            {isEmailField && (
                                <FormRow>
                                    <TextField
                                        label={'Email address'}
                                        name={'email'}
                                        type={'email'}
                                        placeholder={'e.g. name@email.com'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FormRow>
                            )}
                        </FormSection>
                    )}

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Send'}
                            sentText={'Sent!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

MarketingOptInForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

MarketingOptInForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default MarketingOptInForm
